import React from 'react';

import './index.styl';

export default function LegalNotice() {
    return (
        <div className='legal-notice'>
            <h1>Mentions légales MEDIKIOSK</h1>
            <section>
                <h2>Présentation de l’application</h2>
                <p>
                    En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie
                    numérique, il est précisé aux utilisateurs de l’application MediKioskl’identité des différents
                    intervenants dans le cadre de sa réalisation et de son suivi :
                </p>
                <br />

                <h3>Propriétaire : </h3>
                <p>Synertech – n° SIREN: 530 016 682– PARIS, France</p>
                <br />
                <h3>Conception : </h3>
                <p>
                    Web-ID
                    <br />
                    <a href='https://www.web-id.fr' target='_blank'>
                        www.web-id.fr
                    </a>
                    contact@web-id.fr
                    <br />
                    1 rue des Quatre Chapeaux69002 Lyon09 72 17 05 43
                    <br />
                    <br />
                </p>
                <h3>Responsable publication :</h3>
                <p>
                    Edouard BALAS – medikiosk@synertech.biz
                    <br />
                    <br />
                </p>
                <h3>Hébergeur :</h3>
                <p>
                    <a
                        href='https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_French_2020-08-15.pdf'
                        target='_blank'
                    >
                        Amazon
                    </a>
                    La Défense CDG 11 AWS, 31 PL DES COROLLES, Courbevoie - FRANCE
                </p>
            </section>
            <section>
                <h2>Description des services fournis</h2>
                <p>
                    L’application MediKioska pour objet de permettre aux officines de présenter de façon interactive
                    leur offre de matériel médical.
                </p>
                <p>
                    Synertech s’efforce de fournir les outils permettant à ses clients officines ou groupement
                    d’officines de fournir les informations les plus précises possible. Toutefois, Synertech et/ou
                    l’officine ne pourront être tenus responsables des omissions, des inexactitudes et des carences dans
                    la mise à jour, qu’elles soient de leur fait ou du fait des tiers partenaires qui lui fournissent
                    ces informations.
                </p>
                <p>
                    Toutes les informations indiquées sur l’application MediKiosk sont donnéesà titre indicatif, et sont
                    susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur l’application MediKiosk ne
                    sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur
                    mise en ligne.
                </p>
            </section>
            <section>
                <h2>Propriété intellectuelle et contrefaçons</h2>
                <p>
                    Synertech est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur
                    tous les éléments accessibles sur l’application MediKiosk, notamment les textes, images, graphismes,
                    logo, icônes, sons, logiciels.
                </p>
                <p>
                    Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des
                    éléments de l’application, quel que soit le moyen ou le procédé utilisé, est interdite, sauf
                    autorisation écrite préalable de Synertech.
                </p>
                <p>
                    Toute exploitation non autorisée de l’application ou de l’un quelconque des éléments qu’elle
                    contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux
                    dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                </p>
            </section>
            <section>
                <h2>Gestion des données personnelles</h2>
                <p>
                    En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978,
                    la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du
                    24 octobre 1995.
                </p>
                <p>
                    En tout état de cause Synertech ne collecte des informations personnelles relatives à l’utilisateur
                    que pour le besoin de certains services proposés par l’application MediKiosk et le propriétaire de
                    l’officine ou elle est installée. L’utilisateur fournit ces informations en toute connaissance de
                    cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur
                    de l’application MediKiosk l’obligation ou non de fournir ces informations.
                </p>
                <p>
                    Les données marquées par un astérisque dans le questionnaire doivent obligatoirement être fournies.
                    Dans le cas contraire,nous ne pourrons procéder à l’envoi ou l’enregistrement de votre liste.
                </p>
                <p>
                    Les données collectées seront communiquées aux seuls destinataires suivants :le titulaire de la
                    pharmacie et/ou le groupement de pharmacies, Synertech.
                </p>
                <p>Elles sont conservées pendantune durée de 3 ans.</p>
                <p>
                    Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative
                    à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de
                    rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande
                    écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la
                    pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.
                </p>
                <p>
                    Aucune information personnelle de l’utilisateur de l’application MediKioskn’est publiée à l’insu de
                    l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule
                    l’hypothèsedu rachat de Synertech et de ses droits permettrait la transmission des dites
                    informations à l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation
                    et de modification des données vis à vis de l’utilisateur de l’application MediKiosk.{' '}
                </p>
                <p>
                    Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant
                    la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
                </p>
                <p>
                    Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif,
                    vous pouvez contacter Synertech / 10 rue de Penthièvre 75008 Paris / 01 83 79 95 99/
                    medikiosk@synertech.biz
                </p>
                <p>
                    Si vous estimez, après nous avoir contactés, que vos droits «Informatique et Libertés» ne sont pas
                    respectés, vous pouvez adresser une réclamationà la CNIL:{' '}
                    <a href='https://www.cnil.fr' target='_blank'>
                        www.cnil.fr
                    </a>
                </p>
            </section>
            <section>
                <h2>Droit applicable et attribution de juridiction</h2>
                <p>
                    Tout litige en relation avec l’utilisation de l’application MediKiosk est soumis au droit français.
                    Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
                </p>
            </section>
            <section>
                <h2>Les principales lois concernées</h2>
                <p>
                    Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801du 6 août 2004 relative à
                    l’informatique, aux fichiers et aux libertés.Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                    l’économie numérique.
                </p>
            </section>
            <section>
                <h2>Lexique</h2>
                <p>
                    Utilisateur : Personne se connectant, utilisant l’application susnommée.
                    <br />
                    Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit,
                    directement ou non, l’identification des personnes physiques auxquelles elles s’appliquent »
                    (article 4 de la loi n° 78-17 du 6 janvier 1978).
                </p>
            </section>
        </div>
    );
}
