import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './FormMultipleEmail.styl';

const FormMultipleEmail = ({
    emails,
    errors,
    index,
    isShowPlus,
    name,
    onMultipleEmail,
    onRemoveEmail,
    options,
    register,
    setError,
    ...props
}) => {
    function onMultipleAdd() {
        onMultipleEmail();
    }

    function removeItem() {
        onRemoveEmail(index);
    }

    const newProps = {
        ...props,
        name,
        ref: register(options),
    };

    return (
        <div className='input-bloc'>
            <label htmlFor='email' className='h3-like'>
                Adresse Mail
            </label>
            <div className='form-multiple-email'>
                <input
                    {...newProps}
                    // onBlur={handleBlur}
                    autoComplete='off'
                    placeholder='ex : jeandupont@mail.com'
                    className={clsx({ 'input-error': errors[name] })}
                />
                <>
                    {isShowPlus && emails.length < 3 ? (
                        <span className='add-remove'>
                            <button aria-hidden='true' onClick={onMultipleAdd} type='button'>
                                <i className='icon icon-plus' />
                            </button>
                        </span>
                    ) : (
                        ''
                    )}
                    {emails.length > 1 ? (
                        <span className='add-remove'>
                            <button onClick={removeItem} type='button'>
                                <i className='icon icon-close' aria-hidden='true' />
                            </button>
                        </span>
                    ) : (
                        ''
                    )}
                </>
            </div>

            <div className='error-bloc'>{errors[name] && errors[name].message}</div>
        </div>
    );
};

FormMultipleEmail.propTypes = {
    duplicateEmailError: PropTypes.string,
    emails: PropTypes.array.isRequired,
    errors: PropTypes.object,
    index: PropTypes.number.isRequired,
    isShowPlus: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onMultipleEmail: PropTypes.func,
    onRemoveEmail: PropTypes.func.isRequired,
    options: PropTypes.object,
    register: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
};
FormMultipleEmail.defaultProps = {
    errors: null,
    isShowPlus: true,
    onMultipleEmail() {},
    options: {},
};
export default FormMultipleEmail;
