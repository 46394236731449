import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { removeItemFromCart } from '../reducer';

import './RemoveItemButton.styl';

function RemoveItemButton({ itemId, removeItemFromCart }) {
    return (
        <button className='remove-cart-item-button' onClick={() => removeItemFromCart(itemId)}>
            <div className='icon icon-close' />
        </button>
    );
}

RemoveItemButton.propTypes = {
    itemId: PropTypes.string.isRequired,
    removeItemFromCart: PropTypes.func.isRequired,
};

const mapDispatch = { removeItemFromCart };

export default connect(null, mapDispatch)(RemoveItemButton);
