import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { clearCart } from '../reducer';

function ClearCartButton({ clearCart }) {
    return (
        <button onClick={() => clearCart()}>
            <div className='icon icon-delete' />
            <div className='title-subcat'>Vider ma liste</div>
        </button>
    );
}

ClearCartButton.propTypes = {
    clearCart: PropTypes.func.isRequired,
};

const mapDispatch = {
    clearCart,
};

export default connect(null, mapDispatch)(ClearCartButton);
