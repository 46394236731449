import PropTypes from 'prop-types';
import React from 'react';
import { Box, Email, Item, Span } from 'react-html-email';
import Moment from 'react-moment';

function EmailTemplate({ client, list, pharmacy }) {
    const dateToFormat = new Date();
    return (
        <Email title='Votre liste de matériel médical'>
            <Item>
                <Box
                    width='100%'
                    style={{
                        background: '#2B5279',
                        color: '#fff',
                        lineHeight: '1px',
                        padding: '15px',
                    }}
                >
                    <Item>
                        <Span
                            style={{
                                color: '#fff',
                                lineHeight: '1px',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    paddingBottom: '5px',
                                }}
                            >
                                {pharmacy.name}
                            </p>
                            <p>{pharmacy.address},</p>
                            <p>
                                {pharmacy.zip_code} {pharmacy.city}
                            </p>
                            <p>
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Tel :{' '}
                                </span>
                                {pharmacy.phone}
                            </p>
                        </Span>
                    </Item>
                    <Item>
                        <span
                            style={{
                                fontSize: '14px',
                            }}
                        >
                            <p>Liste de : {client}</p>
                            <p>
                                Effectuée le{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    <Moment format='DD/MM/YYYY' date={dateToFormat} />
                                </span>
                            </p>
                        </span>
                    </Item>
                </Box>
                <Box>
                    <Item>
                        <h2 color='#1f3856'>Ma liste de matériel médical</h2>
                    </Item>
                </Box>
            </Item>
            <Item>
                <Box width='100%'>
                    {list.map((item) => (
                        <Box
                            key={item.id}
                            width='100%'
                            style={{
                                borderBottom: '1px solid #2B5279',
                                color: '#4B4D4C',
                                marginBottom: '30px',
                                marginTop: '30px',
                            }}
                        >
                            <Item
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <img
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                    height='auto'
                                    width='200px'
                                    src={item.product.url_images}
                                    alt={item.product.name}
                                />
                                <div
                                    style={{
                                        padding: '10px',
                                    }}
                                >
                                    <Span color='#2B5279'>
                                        <p>
                                            <span
                                                color='#2B5279'
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Nom :
                                            </span>{' '}
                                            {item.product.name}
                                        </p>
                                        <Span color='#2B5279'>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.product.description,
                                                }}
                                            />
                                        </Span>
                                        <div style={{ lineHeight: '1px' }}>
                                            {item.product.brand ? (
                                                <p>
                                                    <Span color='#2B5279' fontWeight='bold'>
                                                        Marque :
                                                    </Span>{' '}
                                                    {item.product.brand}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {item.product.weight ? (
                                                <p>
                                                    <Span color='#2B5279' fontWeight='bold'>
                                                        Poids :
                                                    </Span>{' '}
                                                    {item.product.weight}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {item.product.dimension ? (
                                                <p>
                                                    <Span color='#2B5279' fontWeight='bold'>
                                                        Taille :
                                                    </Span>{' '}
                                                    {item.product.dimension}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            {item.product.maximum_charge ? (
                                                <p>
                                                    <Span color='#2B5279' fontWeight='bold'>
                                                        Charge maximal :
                                                    </Span>{' '}
                                                    {item.product.maximum_charge}
                                                </p>
                                            ) : (
                                                ''
                                            )}
                                            <p>
                                                <Span color='#2B5279' fontWeight='bold'>
                                                    Quantité :
                                                </Span>{' '}
                                                {item.count}
                                            </p>
                                            <p>
                                                <Span color='#2B5279' fontWeight='bold'>
                                                    Prix :
                                                </Span>{' '}
                                                {item.product.price !== 0 ? <>{item.product.price} € TTC</> : 'N/C'}
                                            </p>
                                        </div>
                                    </Span>
                                </div>
                            </Item>
                        </Box>
                    ))}
                </Box>
            </Item>
        </Email>
    );
}

EmailTemplate.propTypes = {
    client: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    pharmacy: PropTypes.object.isRequired,
};

export default EmailTemplate;
