import cn from 'clsx';
import { Layer } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

import './Overlay.styl';

function Overlay({ children, close, full, small }) {
    return (
        <Layer onEsc={close} onClickOutside={close} animate animation='fadeIn' full={full}>
            <div className={cn('app-overlay', { full, small })}>
                <button label='close' onClick={close} className='close-button'>
                    <div className='icon icon-close blue' />
                </button>
                <div className='content'>{children}</div>
            </div>
        </Layer>
    );
}

Overlay.defaultProps = {
    full: false,
    small: false,
};

Overlay.propTypes = {
    children: PropTypes.element.isRequired,
    close: PropTypes.func.isRequired,
    full: PropTypes.bool,
    small: PropTypes.bool,
};

export default Overlay;
