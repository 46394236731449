/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
    body: {
        padding: '0 0 65px',
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '10px',
        color: '#2B5279',
        marginBottom: '25px',
    },
    mrb: {
        marginBottom: '5px',
    },
    textInline: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    marginRight: {
        marginRight: '3px',
    },
    bold: {
        fontWeight: 'bold',
    },
    semiBold: {
        fontWeight: 600,
        fontSize: '8px',
        color: '#4B4D4C',
    },
    center: {
        color: '#173859',
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '20px',
    },
    product: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    productContainer: {
        breakAfter: 'auto',
        breakInside: 'avoid',
        clear: 'both',
        pageBreakAfter: 'auto',
    },
    border: {
        height: '1px',
        width: '100%',
        backgroundColor: '#E7EEF4',
        marginBottom: '15px',
    },
    image: {
        height: '88px',
        width: '88px',
        objectFit: 'contain',
        marginRight: '27px',
    },
    title: {
        color: '#2B5279',
        fontSize: '13px',
        fontWeight: 800,
    },
    description: {
        margin: '6px 0',
    },
    attr: {
        display: 'flex',
        flexBasis: '50%',
        minWidth: '50%',
        maxWidth: '50%',
        flexDirection: 'row',
        marginBottom: '3px',
    },
    text8: {
        fontSize: '8px',
        color: '#4B4D4C',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: '11px',
        bottom: '20px',
        left: 0,
        right: 0,
        textAlign: 'center',
        color: '#4B4D4C',
    },
};
