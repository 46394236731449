/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';

import defaultImage from '../../../../images/broken-img.webp';
import { capitalize } from '../../../../utils/strings';
import styles from './pdfStyle';
import './pdf.styl';

function DocumentToPrint(props, ref) {
    const { data } = props;
    if (data.pharmacyInfos === undefined) {
        return null;
    }
    const { pharmacyInfos } = data;
    const { client, createdAt, images, list } = data.data;

    return (
        <div style={styles.body} ref={ref}>
            <div style={styles.top}>
                <div style={styles.section}>
                    <div style={{ ...styles.bold, ...styles.mrb }}>{pharmacyInfos.name}</div>
                    <div style={styles.mrb}>{pharmacyInfos.address}</div>
                    <div style={{ ...styles.textInline, ...styles.mrb }}>
                        <div style={styles.marginRight}>{pharmacyInfos.zip_code}</div>
                        <div>{pharmacyInfos.city}</div>
                    </div>
                    <div style={{ ...styles.textInline, ...styles.mrb }}>
                        <div style={styles.marginRight}>Tel:</div>
                        <div>{pharmacyInfos.phone}</div>
                    </div>
                </div>

                <div>
                    <div style={styles.mrb}>Liste de Mr / Mme {capitalize(client.lastname)}</div>
                    <div style={styles.textInline}>
                        <div style={styles.marginRight}>Effectuée le</div>
                        <div style={styles.bold}>{createdAt}</div>
                    </div>
                </div>
            </div>

            <div>
                <div style={styles.center}>Ma liste de matériel médical</div>
            </div>
            {list.map((item, i) => {
                return (
                    <div key={item.id} style={styles.productContainer}>
                        <div style={styles.product}>
                            <img style={styles.image} src={images?.[i] ?? defaultImage} alt={item.name} />

                            <div style={{ flex: 2, paddingRight: '20px' }}>
                                <div style={styles.title}>{item.product.name}</div>
                                <div
                                    style={{
                                        ...styles.text8,
                                        ...styles.description,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: item.product.description,
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                    }}
                                >
                                    <div style={styles.attr}>
                                        <div
                                            style={{
                                                ...styles.semiBold,
                                                ...styles.marginRight,
                                            }}
                                        >
                                            Quantité :
                                        </div>
                                        <div style={styles.text8}>{item.count}</div>
                                    </div>

                                    <div style={styles.attr}>
                                        <div
                                            style={{
                                                ...styles.semiBold,
                                                ...styles.marginRight,
                                            }}
                                        >
                                            Location :
                                        </div>
                                        <div style={styles.text8}>{item.product.tag_loc ? 'Oui' : 'Non'}</div>
                                    </div>

                                    {item?.product.colors && item.product.colors !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Couleur :
                                            </div>
                                            <div style={styles.text8}>{item.product.colors}</div>
                                        </div>
                                    ) : null}

                                    <div style={styles.attr}>
                                        <div
                                            style={{
                                                ...styles.semiBold,
                                                ...styles.marginRight,
                                            }}
                                        >
                                            Remboursement :
                                        </div>
                                        <div style={styles.text8}>{item.product.tag_lpp ? 'Oui' : 'Non'}</div>
                                    </div>

                                    {item?.product?.weight && item?.product?.weight !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Poids :
                                            </div>
                                            <div style={styles.text8}>{item.product.weight} kg</div>
                                        </div>
                                    ) : null}

                                    {item.product?.brand && item.product?.brand !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Marque :
                                            </div>
                                            <div style={styles.text8}>{item.product.brand}</div>
                                        </div>
                                    ) : null}

                                    {item?.maximum_charge && item.product.maximum_charge !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Charge max :
                                            </div>
                                            <div style={styles.text8}>{item.product.maximum_charge} kg</div>
                                        </div>
                                    ) : null}

                                    {/* supplier */}
                                    {/* TODO remove after all databases updated */}
                                    {item.product?.supplier && item.product?.supplier !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Fournisseur :
                                            </div>
                                            <div style={styles.text8}>{item.product.supplier}</div>
                                        </div>
                                    ) : null}

                                    {/* suppliers */}
                                    {item.product?.suppliers && item.product?.suppliers.length > 0 ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Fournisseurs :
                                            </div>
                                            <div style={styles.text8}>{item.product.suppliers.join(', ')}</div>
                                        </div>
                                    ) : null}

                                    {/* code ean */}
                                    {item.product?.code_ean && item.product?.code_ean !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Code EAN :
                                            </div>
                                            <div style={styles.text8}>{item.product.code_ean}</div>
                                        </div>
                                    ) : null}

                                    {/* code lpp */}
                                    {item.product?.code_lpp && item.product?.code_lpp !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Code LPP :
                                            </div>
                                            <div style={styles.text8}>{item.product.code_lpp}</div>
                                        </div>
                                    ) : null}

                                    {/* Référence */}
                                    {item.product?.reference && item.product?.reference !== '' ? (
                                        <div style={styles.attr}>
                                            <div
                                                style={{
                                                    ...styles.semiBold,
                                                    ...styles.marginRight,
                                                }}
                                            >
                                                Référence :
                                            </div>
                                            <div style={styles.text8}>{item.product.reference}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div
                                style={{
                                    alignItems: 'flex-end',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    fontSize: 10,
                                }}
                            >
                                <div style={{ color: '#2B5279', fontSize: 10 }}>
                                    {Number(item.product.price) !== 0 ? (
                                        <>
                                            {item.product.price} € <span className='alt'>TTC</span>
                                        </>
                                    ) : (
                                        'N/C'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={styles.border} />
                    </div>
                );
            })}
        </div>
    );
}

export default forwardRef(DocumentToPrint);
