import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { PAGE_CATEGORIES } from '../../../../constants/router';

import './ModalConfirm.styl';

function ModalConfirm({ close, messageType }) {
    function handleQuit() {
        close();
        navigate(PAGE_CATEGORIES);
    }

    return (
        <div className='modal-confirm'>
            <div className='message'>
                Merci ! <br /> Votre liste de matériel a bien été {messageType}.
                <br />A très bientôt
            </div>
            <button className='title-subcat' onClick={() => handleQuit()}>
                Retour à l'accueil
            </button>
        </div>
    );
}

ModalConfirm.propTypes = {
    close: PropTypes.func.isRequired,
    messageType: PropTypes.string.isRequired,
};

export default ModalConfirm;
