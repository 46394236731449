import React from 'react';

import { PAGE_CATEGORIES } from '../../constants/router';
import SEO from '../../views/components/SEO';
import CartList from '../../views/features/cart/CartList/index';
import BreadcrumbLayout from '../../views/layouts/BreadcrumbLayout';

const CartPage = () => {
    return (
        <BreadcrumbLayout
            backLink={{
                label: 'Retour au catalogue',
                path: PAGE_CATEGORIES,
            }}
            headings={<h1 className='alt'>Ma liste de matériel médical</h1>}
        >
            <SEO title='Ma liste' />
            <CartList />
        </BreadcrumbLayout>
    );
};

export default CartPage;
