import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { decrementCartItem, incrementCartItem } from '../reducer';

import './CounterButton.styl';

function CounterButton({ count, decrementCartItem, id, incrementCartItem }) {
    return (
        <div className='product-count-bloc'>
            <button className={count === 1 ? 'disabled' : ''} onClick={() => decrementCartItem(id)}>
                <div className='icon icon-moins' />
            </button>
            <div className='count'>{count}</div>
            <button onClick={() => incrementCartItem(id)}>
                <div className='icon icon-plus' />
            </button>
        </div>
    );
}

const mapDispatch = { decrementCartItem, incrementCartItem };

CounterButton.propTypes = {
    count: PropTypes.number.isRequired,
    decrementCartItem: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    incrementCartItem: PropTypes.func.isRequired,
};

export default connect(null, mapDispatch)(CounterButton);
