import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './CartList.styl';
import useKioskDb from '../../../../database/kiosk/useKioskDb';
import LegalNotice from '../../../components/LegalNotice/index';
import Overlay from '../../../components/Overlay';
import CartListItem from '../CartListItem';
import ClearCartButton from '../ClearCartButton/index';
import FormClient from '../FormClient';
import ModalConfirm from '../ModalConfirm';
import { clearCart } from '../reducer';

function CartList({ clearCart, list, printed, saved, sent }) {
    const [isOpen, setIsOpen] = useState(false);
    const [pharmacy, setPharmacy] = useState('');
    const [mlModalOpen, setMlModalOpen] = useState(false);

    const kioskDb = useKioskDb();

    const onOpenMlModale = (e) => {
        e.preventDefault();
        setMlModalOpen(true);
    };
    const onCloseMlModale = () => setMlModalOpen(undefined);

    useEffect(() => {
        kioskDb.allDocs({ include_docs: true }).then((kiosk) => {
            setPharmacy(kiosk.rows[0].doc.setting);
        });
    }, [kioskDb]);

    if (list.length === 0) {
        return <h3 className='center'>Vous n'avez ajoutez aucun produit à votre liste</h3>;
    }
    return (
        <div className='cart-list'>
            {list.map((item) => (
                <CartListItem key={`cart-list-item-${item.id}`} productData={item} />
            ))}
            <div className='group-button'>
                <ClearCartButton />
                <button onClick={() => setIsOpen(true)}>
                    <div className='icon icon-check_rounded' />
                    <div className='title-subcat'>Valider</div>
                </button>
            </div>
            {isOpen && (
                <Overlay close={() => setIsOpen(false)}>
                    <FormClient
                        close={() => setIsOpen(false)}
                        list={list}
                        pharmacy={pharmacy}
                        onOpenMlModale={onOpenMlModale}
                    />
                </Overlay>
            )}
            {sent && (
                <Overlay close={() => clearCart()} small>
                    <ModalConfirm close={() => clearCart()} messageType='envoyée' />
                </Overlay>
            )}
            {saved && (
                <Overlay close={() => clearCart()} small>
                    <ModalConfirm close={() => clearCart()} messageType='enregistrée' />
                </Overlay>
            )}
            {printed && (
                <Overlay close={() => clearCart()} small>
                    <ModalConfirm close={() => clearCart()} messageType='imprimée' />
                </Overlay>
            )}
            {mlModalOpen && (
                <Overlay close={onCloseMlModale} full>
                    <LegalNotice />
                </Overlay>
            )}
        </div>
    );
}

CartList.propTypes = {
    clearCart: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    printed: PropTypes.bool.isRequired,
    saved: PropTypes.bool.isRequired,
    sent: PropTypes.bool.isRequired,
};

const mapState = (state) => ({
    list: state.cart.list,
    printed: state.cart.printed,
    saved: state.cart.saved,
    sent: state.cart.sent,
});

const mapDispatch = {
    clearCart,
};

export default connect(mapState, mapDispatch)(CartList);
