import { useContext } from 'react';

import DBContext from './DBContext';

export function useDBContext() {
    return useContext(DBContext);
}

export default function useClientsDB(any, { callee = 'useClientsDB', example = 'useDB(options)' } = {}) {
    const dbContext = useDBContext();
    const isSSR = typeof window === 'undefined';

    if (!isSSR && !dbContext) {
        throw new Error(
            callee
                ? `\`${callee}\` was called without \`db\` and database is not in context. Provide database using <PouchDB> or \`${example}\``
                : 'Database is not in context. Provide database using <PouchDB>',
        );
    }
    return dbContext;
}

export const usePostClient = (data) => {
    const db = useClientsDB();
    db.post(data)
        .then((response) => {
            console.info('usePostClient -> response', response);
            return response;
        })
        .catch((err) => {
            console.error('usePostClient -> err', err);
        });
};

export const useGetClientById = (id) => {
    const db = useClientsDB();
    db.get(id)
        .then((response) => {
            console.info('usePostClient -> response', response);
            return response;
        })
        .catch((err) => {
            console.error('usePostClient -> err', err);
        });
};

export const useGetClientList = async () => {
    const db = useClientsDB();
    const allNodes = await db.allDocs({ include_docs: true });
    const doc = allNodes.rows;
    console.info('useGetClientList -> doc', doc);

    return doc;
};
