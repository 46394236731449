import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { getProductPath } from '../../../../utils/products';
import ProductImage from '../../../components/ProductImage/index';
import CounterButton from '../CounterButton/index';
import RemoveItemButton from '../RemoveItemButton';

import './CartListItem.styl';

function CartListItem({ productData }) {
    const { count, id, product } = productData;
    const { brand, code_ean, colors, description, name, price, reference } = product;

    return (
        <div className='cart-list-item'>
            <div className='img-container'>
                <ProductImage {...product} />
            </div>
            <div className='right'>
                <Link to={getProductPath(productData.product)} className='name product-label'>
                    {name}
                </Link>
                <div
                    className='description txt-description'
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />

                {colors ? (
                    <div className='color txt-description'>
                        <span className='bold'>Couleur : </span>
                        {colors}
                    </div>
                ) : null}

                {brand ? (
                    <div className='options txt-description'>
                        <span className='bold'>Marque : </span>
                        {brand}
                    </div>
                ) : null}

                {code_ean ? (
                    <div className='options txt-description'>
                        <span className='bold'>EAN : </span>
                        {code_ean}
                    </div>
                ) : null}

                {reference ? (
                    <div className='options txt-description'>
                        <span className='bold'>Référence : </span>
                        {reference}
                    </div>
                ) : null}

                <CounterButton count={count} id={id} />

                <div className='price'>
                    <div className='price-label blue'>
                        {Number(price) !== 0 ? (
                            <>
                                {price} € <span className='alt'>TTC</span>
                            </>
                        ) : (
                            'N/C'
                        )}
                    </div>
                    {Number(price) !== 0 ? <span>(prix de vente conseillé)</span> : null}
                </div>
            </div>
            <RemoveItemButton itemId={id} />
        </div>
    );
}

CartListItem.propTypes = {
    productData: PropTypes.object.isRequired,
};

export default CartListItem;
