import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { renderEmail } from 'react-html-email';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import isEmail from 'validator/es/lib/isEmail';

import fields from '../../../../constants/fields';
import { useCentralizedImagesDb } from '../../../../database/centralizedImages/useCentralizedImagesDb';
import useClientsDB from '../../../../database/clients/useClientsDb';
import useKioskDb from '../../../../database/kiosk/useKioskDb';
import { getImagesSrc } from '../../../../hooks/useImagesSrc';
import { formatDateDDMMYYYY } from '../../../../utils/date';
import EmailTemplate from '../../../components/EmailTemplate';
import FormCheckBox from '../../../components/FormCheckBox';
import FormError from '../../../components/FormError';
import FormMultipleEmail from '../../../components/FormMultipleEmail';
import InputBloc from '../../../components/InputBloc';
import DocumentToPrint from '../../../features/print/DocumentToPrint';
import { printCart, saveCart, sendCart } from '../reducer';

import './FormClient.styl';

function FormClient({ close, list, onOpenMlModale, pharmacy, printCart, saveCart, sendCart }) {
    const { firstName, lastName, phone } = fields();
    const { errors, getValues, handleSubmit, register, setError, setValue } = useForm();

    const componentRef = useRef();

    const [emails, setEmails] = useState([{ email: '' }]);
    const [printData, setPrintData] = useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loginError, setLoginError] = useState(null);

    const kioskDb = useKioskDb();
    const clientDb = useClientsDB();
    const imageDb = useCentralizedImagesDb();

    const [settings, setSettings] = useState(null);

    useEffect(() => {
        kioskDb.allDocs({ include_docs: true }).then((allNodes) => {
            const { setting } = allNodes.rows[0].doc;
            setSettings(setting);
        });
    }, [kioskDb]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function save(data, type) {
        const date = new Date();
        const newClient = {
            _id: date.getTime().toString(),
            client: data,
            createdAt: formatDateDDMMYYYY(date),
            createdAtTime: date.getTime(),
            deleted_at: null,
            list,
        };

        clientDb
            .put(newClient)
            .then(() => {
                switch (type) {
                    case 'send':
                        sendCart();
                        break;
                    case 'print':
                        printCart();
                        break;
                    case 'save':
                        saveCart();
                        break;
                }

                close();
            })
            .catch((err) => {
                console.error('usePostClient -> err', err);
            });
    }

    const onSubmit = handleSubmit((data) => {
        let errors = 0;
        const name = data.firstname + ' ' + data.lastname;
        const tempArray = [];
        emails.forEach((email, index) => {
            if (data[`email-${index}`] === '' && index === 0) {
                setError(`email-${index}`, {
                    message: 'Ce champ est requis',
                    type: 'manual',
                });
                errors += 1;
            }
            if (data[`email-${index}`] !== '') {
                if (!isEmail(data[`email-${index}`])) {
                    setError(`email-${index}`, {
                        message: "Le format du mail n'est pas valide",
                        type: 'manual',
                    });
                    errors += 1;
                }
            }
            for (let i = 0; i <= emails.length - 1; i++) {
                if (i !== index) {
                    if (data[`email-${i}`] === data[`email-${index}`]) {
                        tempArray.push(i);
                    }
                }
            }
        });
        if (tempArray.length > 0) {
            setError(`email-${tempArray[0]}`, {
                message: 'Ce mail est déjà utilisé',
                type: 'manual',
            });
            errors += 1;
        }
        if (errors > 0) return;
        console.info('onSubmit -> data', data);

        const getDest = () => {
            const dest = [];
            emails.forEach((email, i) => {
                if (data[`email-${i}`] !== '') {
                    dest.push({
                        email: data[`email-${i}`],
                        name: data.firstname + ' ' + data.lastname,
                    });
                }
            });
            return dest;
        };

        const getBbc = () => {
            let emails = settings.email;
            if (typeof emails === 'string') {
                emails = [settings.email];
            }
            return emails.map((email) => ({ email, name: email }));
        };

        const config = {
            bcc: getBbc(),
            htmlContent: renderEmail(<EmailTemplate list={list} pharmacy={pharmacy} client={name} />),
            sender: {
                email: 'medikiosk@synertech.biz',
                name: `De ${pharmacy.name}`,
            },
            subject: 'Votre liste de matériel médical',
            textContent: 'hello',
            to: getDest(),
        };

        const options = {
            data: config,
            headers: {
                Accept: 'application/json',
                'api-key': process.env.GATSBY_SIB_API_KEY,
                'content-type': 'application/json',
            },
            method: 'POST',
            url: 'https://api.sendinblue.com/v3/smtp/email',
        };

        import('axios-offline').then((module) => {
            const AxiosOffline = module.default;
            const AxiosOfflineAdapter = AxiosOffline({
                defaultAdapter: axios.defaults.adapter, //require, basic adapter
                storageName: 'axios-offline', //optional, default: "axios-stack"
            });

            const http = axios.create({
                adapter: AxiosOfflineAdapter,
            });

            http(options)
                .then((response) => {
                    console.info('onSave -> response', response);
                })
                .catch((err) => {
                    console.error('onSave -> err', err);
                });
            save(data, 'send');
        });
    });

    const onSave = handleSubmit((data) => {
        let errors = 0;
        if (emails.length > 0) {
            emails.forEach((item, i) => {
                if (data[`email-${i}`] !== '' && !isEmail(data[`email-${i}`])) {
                    setError(`email-${i}`, {
                        message: "Le format du mail n'est pas valide",
                        type: 'manual',
                    });
                    errors += 1;
                }
            });
            if (errors > 0) return;
        }

        save(data, 'save');
    });

    const onPrint = handleSubmit((data) => {
        console.info('onPrint -> data', data);
        let errors = 0;
        if (emails.length > 0) {
            emails.forEach((item, i) => {
                if (data[`email-${i}`] !== '' && !isEmail(data[`email-${i}`])) {
                    setError(`email-${i}`, {
                        message: "Le format du mail n'est pas valide",
                        type: 'manual',
                    });
                    errors += 1;
                }
            });
            if (errors > 0) return;
        }
        const details = {
            client: data,
            createdAt: formatDateDDMMYYYY(new Date()),
            list,
        };

        details.list = list;

        kioskDb.allDocs({ include_docs: true }).then((kiosk) => {
            const images = list.map((item) => item.product?.images?.[0] ?? 'default');
            getImagesSrc(images, imageDb).then((src) => {
                details.images = src;

                setPrintData({
                    data: details,
                    pharmacyInfos: kiosk.rows[0].doc.setting,
                });

                handlePrint();
                save(data, 'print');
            });
        });
    });

    const onMultipleEmail = () => {
        if (emails.length < 3) {
            const newArr = [...emails];
            newArr.push({ email: '' });
            setEmails(newArr);
        }
    };

    const onRemoveEmail = (index) => {
        const test = emails.filter((item, i) => i !== index);
        if (index === 0) {
            for (let i = 0; i < emails.length; i++) {
                setValue(`email-${i}`, getValues(`email-${i + 1}`));
            }
        } else if (index === 1) {
            setValue(`email-1`, getValues(`email-2`));
        }
        setEmails(test);
    };

    return (
        <div className='form-client'>
            <div className='title-cat center'>
                En complétant les informations ci-dessous, <br />
                nous pourrons vous envoyer votre liste par mail. Merci !
            </div>
            <form name='FormLogin'>
                <div className='input-group'>
                    <InputBloc {...firstName} register={register} errors={errors} required autoComplete='off' />
                    <InputBloc {...lastName} register={register} errors={errors} required autoComplete='off' />
                    {emails.map((item, i) => {
                        return (
                            <FormMultipleEmail
                                key={`form-email-${i}`}
                                isShowPlus={emails.length - 1 === i}
                                emails={emails}
                                index={i}
                                onMultipleEmail={onMultipleEmail}
                                register={register}
                                onRemoveEmail={onRemoveEmail}
                                errors={errors}
                                options={{}}
                                required={false}
                                setError={setError}
                                name={`email-${i}`}
                            />
                        );
                    })}
                    <InputBloc {...phone} register={register} errors={errors} required={false} autoComplete='off' />

                    <FormCheckBox
                        label={
                            <>
                                <span>
                                    La solution MediKiosk de Synertech collecte vos données afin de permettre à votre
                                    pharmacien d’assurer le bon suivi de vos besoins en matière de matériel médical.
                                    <br />
                                    Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos
                                    droits,
                                    <button type='button' onClick={onOpenMlModale} className='checkbox-link'>
                                        reportez-vous à la notice ci-jointe
                                    </button>
                                </span>
                            </>
                        }
                        name='accept-charte'
                        register={register}
                        errors={errors}
                    />

                    <FormError
                        formError={loginError}
                        errorMessages={{
                            401: 'Email ou mot de passe incorrect',
                        }}
                    />
                </div>

                <div style={{ display: 'none' }}>
                    <DocumentToPrint data={printData !== null ? printData : {}} ref={componentRef} />
                </div>

                <div className='group-button'>
                    <button className='border' type='button' onClick={close}>
                        <div className='icon icon-arrow-small-left' />
                        <div className='title-subcat'>Retour liste</div>
                    </button>
                    <button className='border' type='button' onClick={onPrint}>
                        <div className='icon icon-print' />
                        <div className='title-subcat'>Imprimer</div>
                    </button>
                    <button className='border' type='submit' onClick={onSave}>
                        <div className='icon icon-save' />
                        <div className='title-subcat'>Sauvegarder</div>
                    </button>
                    <button className='border' type='submit' onClick={onSubmit}>
                        <div className='icon icon-check_rounded' />
                        <div className='title-subcat'>Envoyer</div>
                    </button>
                </div>
            </form>
        </div>
    );
}

FormClient.propTypes = {
    close: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    onOpenMlModale: PropTypes.func.isRequired,
    pharmacy: PropTypes.object.isRequired,
    printCart: PropTypes.func.isRequired,
    saveCart: PropTypes.func.isRequired,
    sendCart: PropTypes.func.isRequired,
};

const mapDispatch = {
    printCart,
    saveCart,
    sendCart,
};

export default connect(null, mapDispatch)(FormClient);
