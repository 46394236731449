import { CheckBox } from 'grommet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './FormCheckBox.styl';

function FormCheckBox({ defaultCheck, errors, label, name, options, register, required }) {
    const [checked, setChecked] = useState(defaultCheck);

    if (required) {
        options.required = 'Vous devez accepter en cochant la case';
    }

    const props = {
        name,
        ref: register(options),
    };

    return (
        <div className='custom-checkbox'>
            <CheckBox
                {...props}
                checked={checked}
                label={label}
                onChange={(event) => setChecked(event.target.checked)}
            />
            <div className='error-bloc'>{errors[name] && errors[name].message}</div>
        </div>
    );
}

FormCheckBox.defaultProps = {
    defaultCheck: false,
    errors: null,
    options: {},
    required: true,
};

FormCheckBox.propTypes = {
    defaultCheck: PropTypes.bool,
    errors: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.object,
    register: PropTypes.func.isRequired,
    required: PropTypes.bool,
};

export default FormCheckBox;
